.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#video {
  position: relative;
  top: -450px;
  top_: -100px;
  z-index: -1;
  width: 100% !important;
  opacity_: 0.4;
  overflow: hidden;
}

#MovieDiv {
  width: 100%;
  position: relative;
  background-color:#ccc;
  height: 300px;
  max-height_: 300;
  opacity: 0.5;
  z-index: 1;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.QmeButton {
  color: #fff;
  border: 2px solid #FFC90E;
  background-color: #FFC90E !important;
  border-color: #FFC90E !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.QmeButtonDisabled {
  color: #eee;
  border: 2px solid #999;
  background-color: #ccc !important;
  border-color: #bbb !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.test{
  background-color: #61dafb;
}




